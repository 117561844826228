import React from "react";

export default function Score({ score, rating }) {
	return (
		<>
			<div className="ranks">
				{/* <div className="border"></div> */}
				<div id="points">
					<div className="inside-ranks">
						<div className="text">Points</div>
						<div className="value">{score}</div>
					</div>
					<div className="stars">
						<img
							src="/img/star-icon-or-symbol-success-ranking-element-icon-concept-cartoon-illustration-3d-rendering-3.png"
							className="logo"
						/>
						<img src="/img/star_2.png" className="logo" />
						<img src="/img/star_3.png" className="logo" />
					</div>
				</div>
				{/* <div className="border"></div> */}
				<div id="local-rank">
					<div className="inside-ranks">
						<div class="text"> Rank</div>
						<div
							data-rating={rating}
							// class={`value ${
							// 	rating < 4 ? "top" : rating > 15 ? "low" : "middle"
							// }`}
							className="value"
						>
							#{rating}
						</div>
					</div>
					<img src="/img/cubok.png" className="logo"></img>
				</div>
				{/* <div className="border"></div> */}
				{/* <input
					type="file"
					capture="camera"
					accept="image/*"
					id="cameraInput"
					name="cameraInput"
				></input> */}
			</div>
		</>
	);
}
