import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "./User";
import { TieredProgressBarComponent } from "@insertcoinab/gwen-react-components";

const ProgressBar = ({
	label,
	background = "#e5e5e5",
	// expected format for visual parts
	visualParts = {
		percentage1: 0,
		percentage2: 0,
		points1: 0,
		points2: 0,
		totalPoints: 0,
		background: "white",
		text: "progress",
	},
}) => {
	const [completed, setCompleted] = useState(0);
	const { user } = useContext(UserContext);

	useEffect(() => {
		const score = user.score;
		setCompleted(score);
	}, [user]);

	const fillerStyles = {};

	const labelStyles = {
		padding: 5,
		color: "rgb(60 58 51)",
		fontWeight: "bold",
		// marginLeft: 25,
	};

	const Tiers = [
		{
			startStep: 1,
			endStep: 30,
		},
		{
			startStep: 31,
			endStep: 60,
		},
		{
			startStep: 61,
			endStep: 90,
		},
	]

	const [state, setState] = React.useState({
		step: 1,
		theme: { icon: 'circle', primary: '#5dca7a', background: '#d5faee' },
	})
	const themes = [
		{ icon: 'circle', primary: '#5dca7a', background: '#d5faee' },
		{ icon: 'triangle', primary: '#2583bc', background: '#d6effe' },
		{ icon: 'square', primary: '#ebd10a', background: '#fffad9' },
		{ icon: 'star', primary: '#ae72fb', background: '#f4edfe' },
	]

	return (
		<div className="progress-bar">
			<TieredProgressBarComponent
				stepNumber={visualParts.percentage1}
				tiers={Tiers}
				icon={state.theme.icon}
				color={state.theme.primary}
				background={state.theme.background}
			/>
			{/* <div className="filler">
				<span style={labelStyles}></span>
			</div> */}
		</div>
	);
};

export default ProgressBar;
