import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import { UserContext } from "./User";
import PopUpMessage from "./PopUpMessage";

export default function BusinessCard() {
	const [backSide, setBackSide] = useState(false);
	const [popup, setPopup] = useState(false);
	const { user } = useContext(UserContext);
	const navigate = useNavigate();

	const openBusinessCard = () => {
		setBackSide(true);
		setPopup(true);
	};

	const completeProfile = () => {
		if (user.progress[user.project_id].bio)
			navigate(user.progress[user.project_id].bio.link);
	};

	const handleShowDetails = () => {
		setPopup(false);
		setBackSide(false);
	};

	function onModalChange(newState) {   
		setPopup(newState);
	}

	//console.log(user.progress);

	return !backSide ? (
		user.profileComplete ? (
			<button className="business-card-btn" onClick={openBusinessCard}>
				View my Business Card
			</button>
		) : (
			user && user.progress && user.progress[user.project_id] && user.progress[user.project_id].bio ?
				<button className="business-card-btn" onClick={completeProfile}>
					Complete my profile
				</button>
			:
			null
		)
	) : (
		<div className="back-side">
			<PopUpMessage onModalChange={onModalChange} trigger={popup}>
				{
					<>
						<div className={`business-card`}>
							<img
								className="avatar"
								src={user.avatarUrl}
								alt="user avatar"
							/>
							<div className="names">
								{user.firstName} {user.lastName}
							</div>
							<hr/>

							<div className="card-email"><p class="highlight">My email is</p> {user.corporateEmail}</div>
							<div className="card-company"><p class="highlight">I work at</p>{user.companyName}</div>
						</div>
						<button className="show-details" onClick={handleShowDetails}>
							Close My Card
						</button>
					</>
				}
			</PopUpMessage>
		</div>
	);
}
