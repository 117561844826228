import { useState, useEffect } from "react";
import "mind-ar/dist/mindar-face.prod.js";
import "aframe";
import "mind-ar/dist/mindar-face-aframe.prod.js";
import MindARViewer2 from "./mindar-viewer2.js";
import ArrowLeft from "./ArrowLeft";
import { useLocation } from "react-router-dom";

export default function Avatar() {

	const location = useLocation();

	const [points, setPoints] = useState(0);
	useEffect(() => {
		if (location.state) {
			setPoints(location.state.points)
		}
	}, [location.state]);

	return (
		<div className="ar">
			<header>
				<div className="top">
					<ArrowLeft />
					Create your avatar
				</div>
			</header>
			<MindARViewer2 points={points} />
			<video></video>
		</div>
	);
}
