import React, { useContext, useEffect, useMemo, useState } from "react";
import db from "../firebase";
import { onSnapshot, collection } from "firebase/firestore";
import Cookies from "js-cookie";
import { useNavigate, Navigate } from "react-router-dom";
import { UserContext } from "./User";
// import "../styles/Intro.scss";

const Intro = () => {
	const [input, setInput] = useState("");
	const [users, setUsers] = useState([]);
	const { user, setUser } = useContext(UserContext);

	const navigate = useNavigate();

	useEffect(() => {
		const unsub = onSnapshot(collection(db, "users"), (snapshot) =>
			setUsers(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
		);
		return () => unsub();
	}, []);

	const handleSubmit = (e) => {
		e.preventDefault();
		users.forEach((user) => {
			if (user.companyId === input) {
				Cookies.set("id", user.id, { expires: 90 });
				setUser(user);
				return user;
			}
		});
		setInput("");
	};

	const signForm = (e) => {
		navigate("./signup");
	};

	return Cookies.get().id ? (
		<Navigate to="/" />
	) : (
		<div className="intro">
			<h1>Welcome!</h1>
			{/* <p>please enter your company ID below</p> */}
			<form>
				<label htmlFor="company">Input ID</label>
				<input
					placeholder="Input your company id here"
					onChange={(e) => setInput(e.target.value)}
					value={input}
					id="company"
					name="company"
					type="text"
				/>
				<button
					className="bg-blue-400 rounded px-1 py-1 hover:bg-blue-500 text-center text-white font-medium"
					onClick={handleSubmit}
					type="submit"
				>
					Log in
				</button>
				<button
					className="bg-blue-400 rounded px-1 py-1 hover:bg-blue-500 text-center text-white font-medium"
					onClick={signForm}
					type="submit"
				>
					Sign up
				</button>
			</form>
		</div>
	);
};

export default Intro;
