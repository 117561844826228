import { react, useContext, useEffect, useState } from "react";
import { UserContext } from "./User";
import {
	doc,
	collection,
	updateDoc,
	query,
	deleteDoc,
	deleteField,
	onSnapshot,
} from "firebase/firestore";
import db from "../firebase";
import Activities from "./Activities";
import { DataContext } from "./DataContext";
import ArrowLeft from "./ArrowLeft";
import Cookies from "js-cookie";
import Badges from "./Badges";
import Badge from "./Badges2";

export default function Packets() {

    const {DATA, setData} = useContext(DataContext);

	const [users, setUsers] = useState([]);
	const [packets, setPackets] = useState(false);
	const [badges2, setBadges] = useState([]);

	const [status, setStatus] = useState(0);

	const {user, setUser} = useContext(UserContext);

	const removeFour = async () => {
		await deleteDoc(doc(db, "memory", "gameStart"));
		// add logic to delete lotterWinner prop from user.
		const userArray = users.map((user) => user.id);
		userArray.forEach(async (id) => {
			const ref = await doc(db, "users", id);
			updateDoc(ref, {
				fourNums: deleteField(),
				matchNumber: deleteField(),
			});
		});
	};

	useEffect(() => {
		if (users.length === 0) {
			onSnapshot(collection(db, "users"), (snapshot) => {
				setUsers(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
			});
		} else {
			users.forEach((user) => {
				if (user.id === Cookies.get().id) {

					//console.log('setUser - packets');
					setUser({ ...user });
				}
			});
		}
	}, []);

	useEffect(() => {

		//console.log('#1');

		setStatus(!status);

		//console.log(user);

		if (user.setprogress){

			//console.log('#2');


			var tempPackets = [];
			//var tempBadges = [];

			////console.log(DATA);
			////console.log(user);

			if (DATA['PACKETS'] && user.progress && DATA.ID && user.progress[DATA.ID]) {

				DATA['PACKETS'].forEach((packet, i) => {

					/*
					let sortedProgress = Object.fromEntries(
						Object.entries(user.progress[DATA.ID]).sort()
					);
					*/
					let propsObj = {
						packet: packet,
						//progress: Object.values(sortedProgress)[i],
						progress: user.progress[DATA.ID]['packets'][i],
					};



					//console.log('add activity');
					////console.log(propsObj);


					////console.log('key='+i);

					let unlock = false;
					if (DATA['unlock'])
						unlock = DATA['unlock'];

					if (propsObj['packet'] && propsObj['progress']){
						tempPackets.push(<Activities props={propsObj} i={i} unlock={unlock}/>);

					}
					//tempBadges.push(<Badge badge={propsObj.progress.badge} num={i} />);
				});



			}
			setPackets(tempPackets);
			//console.log('set packets');
		}


		//setBadges(tempBadges);

	}, [user, user.setprogress, DATA, DATA['existingProject'], DATA['unlock']]);

/*
	useEffect(() => {
		if (users) {
			users.forEach((user) => {
				if (user.id === Cookies.get().id) {
					setUser({ ...user });
				}
			});
		}
	}, [users]);
*/



	return (
		<div className="packets">
			<header>
				<div className="top">
					<ArrowLeft />
					Activities
				</div>
			</header>
			{/* <div className="no-activity">
				<p>You have no active tasks!</p>
				<p className="qr-no-activity">Scan QR code</p>
				<svg
					width="20"
					height="41"
					viewBox="0 0 20 41"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M10 2L10 37"
						stroke="#B63FFF"
						stroke-width="4"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M10.86 40.4738C10.5467 41.1754 9.45328 41.1754 9.13997 40.4738L0.069017 20.1602C-0.178565 19.6058 0.270493 19 0.929053 19C0.929053 19 6.10012 22.7946 10 22.7946C13.8999 22.7946 19.0709 19 19.0709 19C19.7295 19 20.1786 19.6058 19.931 20.1602L10.86 40.4738Z"
						fill="#B63FFF"
					/>
				</svg>
			</div> */}
			{/* <div class="row badges">{badges}</div> */}

			{packets}
			<button onClick={removeFour}>Reset Timer</button>
		</div>
	);
}
