import { set } from "firebase/database";
import { React, useState, useEffect, useContext } from "react";
// import { ReactComponent as LockIcon } from "../assets/B1.svg";
// import { ReactComponent as ReactLogo3 } from "../assets/module-2.png";
import { DataContext } from "./DataContext";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css'

import SwiperCore, { Navigation } from 'swiper/core';

import Badge from "./Badge";

export default function Badges({badges}) {

	SwiperCore.use([Navigation]);

	const [displayBadges, setDisplayBadges] = useState(badges);
	const [displayActivities, setDisplayActivities] = useState(false);
	const {DATA, setData} = useContext(DataContext);

	const [activeIndex, setActiveIndex] = useState(0);
	useEffect(() => {
		if (DATA['unlock'])
			setActiveIndex(DATA['unlock']);

	}, [DATA, DATA['unlock']]);

	console.log('badges');
	console.log(badges);
	console.log(DATA);
    


    console.log(DATA.PACKETS);
	const [showSlider, setShowSlider] = useState(false);
	useEffect(() => {

		if (DATA.PACKETS && DATA.PACKETS.length > 3)
			setShowSlider(true);

	}, [DATA, DATA.PACKETS]);


    return (
        <>
            <div className="row badges">
                {badges &&
                    badges
                        .map((badgeStatus, index) => ({ badgeStatus, index }))
                        .filter(({ badgeStatus }) => badgeStatus === 'is_awarded')
                        .map(({ index }) => {
                            const packet = DATA.PACKETS[index];
                            return (
                                <Badge 
                                    key={index} 
                                    packetIndex={index} 
                                    badges={badges} 
                                    packet={packet} 
                                />
                            );
                        })
                }
            </div>
        </>
    );
}
