import react, { useEffect, useState, useContext } from "react";
import Footer from "./Footer";
import TaskCard from "./TaskCard";
import PopUpMessage from "./PopUpMessage";
import useScore from "../hooks/useScore";
import Cookies from "js-cookie";
import useAnalytics from "../hooks/useAnalytics";
import ConfettiComp from "./ConfettiComp";
import { useParams, useLocation} from "react-router-dom";
import ArrowLeft from "./ArrowLeft";
import { updateDoc, doc, onSnapshot } from "firebase/firestore";
import db from "../firebase";
import { UserContext } from "./User";

import { DataContext } from "./DataContext";

export default function Quiz() {

	const {DATA, setData} = useContext(DataContext);

	const location = useLocation();
	const { packet_id } = location.state;
	const { activity_i } = useParams();
	const activity_index = activity_i - 1;
	const packet_index = parseInt(packet_id.replace(/packet_/,'')-1);
	const { points } = location.state;

	const { data } = location.state;

	const [selected, setSelected] = useState();
	const [correct, setCorrect] = useState();
	const [answer, setAnswer] = useState("");
	const [popUp, setPopUp] = useState(false);
	const [popup, setPopup] = useState("");
	const user_id = Cookies.get("id");
	const [updateScore] = useScore(points, user_id);
	const [addAnalytics1] = useAnalytics("quiz answer is correct", user_id, {});
	const [addAnalytics2] = useAnalytics("quiz answer is incorrect", user_id, {});
	const [fire, setFire] = useState(false);
	const {user, setUser} = useContext(UserContext);
	const [package_points, setPackage_points] = useState(0);

	useEffect(() => {
		console.log(DATA);
 	}, [DATA]);

// 	useEffect(() => {
// 		if (user_id !== undefined) {
// 			const unsub = onSnapshot(doc(db, "users", user_id), (doc) => {
// 				console.log("get user from cookie - quiz");
// 				setUser(doc.data());
// 			});
// 			return () => unsub();
// 		}
// 	}, [user_id]);


	useEffect(() => {
		if (user.progress && user.project_id && user.progress[user.project_id]) {
			setPackage_points(user.progress[user.project_id]['packets'][packet_index].package_points);
		}
	}, [user, user.progress, user.project_id]);

	function onModalChange(newState) {
		setPopUp(newState);
	}

	const handleSelect = (e) => {
		setSelected(e.target.value);
	};

	const update = async (selected) => {


		const user_ref = await doc(db, "users", Cookies.get().id);

// 		console.log(user.progress);
// 		console.log(user.project_id);
// 		console.log(packet_id);
// 		console.log(user.progress[user.project_id]['packets'][packet_index]);
// 		console.log(activity_index);


		console.log('user.project_id ' + user.project_id)
		console.log('packet_index ' + packet_index)
		console.log('activity_index ' + activity_index)


		user.progress[user.project_id]['packets'][packet_index].activities[activity_index].completed = true;
		user.progress[user.project_id]['packets'][packet_index].activities[activity_index].answer = answer;
		user.progress[user.project_id]['packets'][packet_index].package_points = parseInt(package_points) + parseInt(points);

		console.log(DATA);

		if (DATA['PACKETS'][packet_index].activities[activity_index]['reward']){

			let reward = DATA['PACKETS'][packet_index].activities[activity_index]['reward'];

			if (reward.name != ''){
				user.rewards.push({
					name: reward.name,
					price: reward.price,
					image: reward.image ? reward.image : '',
					redeemed: false,
					instruction: reward?.instruction,
				});
			}
		}

		user.setprogress = false;
		setUser(Object.assign({}, user));

		//user.rewards

		//console.log(user.progress[user.project_id]['packets'][packet_index]);

		console.log({
			progress: user.progress,
			rewards: user.rewards,
		});

		await updateDoc(user_ref, {
			progress: user.progress,
			rewards: user.rewards,
		});
		console.log('update user progress');

		/*
		if (packet_id === "packet_1") {
			await updateDoc(user_ref, {
				"progress.packet_1.activities.quiz.completed": true,
				"progress.packet_1.activities.quiz.answer": answer,
				"progress.packet_1.package_points": package_points + points,
			});
		} else if (packet_id === "packet_2") {
			await updateDoc(user_ref, {
				"progress.packet_2.activities.quiz.completed": true,
				"progress.packet_2.activities.quiz.answer": answer,
			});
		}
		*/

	};

	/*
	const updateScore1 = async (increment) => {
		const user_ref = await doc(db, "users", Cookies.get().id);
		await updateDoc(user_ref, {
			score: user.score + increment,
		});
	};
	*/
	useEffect(() => {
		if (selected) {
			// setCorrect(selected === "answer2");
			setAnswer(selected);
		}
		// if (selected) {
		// 	updateScore();
		// 	setPopUp(true);
		// 	update();
		// 	addAnalytics1();
		// }
	}, [selected]);

	// this code if we have only 1 correct answer from radio buttons
	useEffect( async () => {
		if (answer) {
			setPopup("Good to know!");
			setPopUp(true);
			await updateScore();
			await addAnalytics1();
			update(true);
			setFire(true);
		}
		// } else if (selected && !correct) {
		// 	setPopup(`Oops, ${data.answer2}, was the correct answer!`);
		// 	updateScore1(0);
		// 	// addAnalytics2();
		// 	setPopUp(true);
		// 	update(false);
		// }
	}, [answer]);

	return (
		<>

			<div className="quiz">
				<header>
					<div className="top">
						<ArrowLeft />
						Quiz
					</div>
				</header>
				<TaskCard taskDescription={data.taskDescription} />
				{/* <div className="questions">
					<p className="question">Question 1</p>
					<p className="q-description">
						When I get multiplied by any number, the sum of the figures in a
						product is always me. What am I?
					</p>
					<form>
						<input
							type="radio"
							checked={selected === "answer1"}
							value="answer1"
							onChange={(e) => {
								handleSelect(e);
							}}
						/>
						<label>10</label>
						<br />
						<input
							type="radio"
							checked={selected === "answer2"}
							value="answer2"
							onChange={(e) => {
								handleSelect(e);
							}}
						/>
						<label>9</label>
						<br />
						<input
							type="radio"
							checked={selected === "answer3"}
							value="answer3"
							onChange={(e) => {
								handleSelect(e);
							}}
						/>
						<label>5</label>
						<br />
						<input
							type="radio"
							checked={selected === "answer4"}
							value="answer4"
							onChange={(e) => {
								handleSelect(e);
							}}
						/>
						<label>11</label>
					</form>
				</div> */}
				<ConfettiComp fire={fire} />
				<div className="questions">
					<p className="question">Question</p>
					<p className="q-description">{data.question}</p>
					<li>
						<input
							type="radio"
							checked={selected === "answer1"}
							value={data.answer1}
							id="answer1"
							onChange={(e) => {
								handleSelect(e);
							}}
						/>
						<label htmlFor="answer1">{data.answer1}</label>
						<input
							type="radio"
							checked={selected === "answer2"}
							value={data.answer2}
							id="answer2"
							onChange={(e) => {
								handleSelect(e);
							}}
						/>
						<label htmlFor="answer2">{data.answer2}</label>
						<input
							type="radio"
							checked={selected === "answer3"}
							value={data.answer3}
							id="answer3"
							onChange={(e) => {
								handleSelect(e);
							}}
						/>
						<label htmlFor="answer3">{data.answer3}</label>
						{/* <br /> */}
						<input
							type="radio"
							checked={selected === "answer4"}
							value={data.answer4}
							id="answer4"
							onChange={(e) => {
								handleSelect(e);
							}}
						/>
						<label htmlFor="answer4">{data.answer4}</label>
					</li>
				</div>
				<PopUpMessage onModalChange={onModalChange} trigger={popUp}>
					{
						<div>
							<h2>Thank you for your response!</h2>
							<p>{popup}</p>
						</div>
					}
				</PopUpMessage>
				<footer>
					<Footer />
				</footer>
			</div>
		</>
	);
}
