import { react, useContext, useEffect, useState } from "react";
import { UserContext } from "./User";
import Cookies from "js-cookie";
import useCookie from "../hooks/useCookie";
import { DataContext } from "./DataContext";
import ArrowLeft from "./ArrowLeft";
import PopUpBio from "./PopUpBio";
import ConfettiComp from "./ConfettiComp";
import ProgressBar2 from "./ProgressBar2";
import { addDoc, orderBy, query } from "firebase/firestore";
import { doc, onSnapshot, updateDoc, collection } from "@firebase/firestore";
import db from "../firebase";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import { ProgressCircleComponent } from '@insertcoinab/gwen-react-components';


export default function Rewards() {
    
    const {DATA, setData} = useContext(DataContext);
    
	let cookie = Cookies.get().id;
	const [value, update, remove] = useCookie("id", cookie);
	const { user, setUser } = useContext(UserContext);
	const [users, setUsers] = useState([]);
	const [rating, setRating] = useState(1);
	const [score, setScore] = useState(0);
	const [name, setName] = useState("");
	const [userAvatar, setUserAvatar] = useState("/img/avatar-1.png");
	const [avatar, setAvatar] = useState("/img/avatar-1.png");
	const [classChange, setClassChange] = useState(false);
	const [popupText, setPopupText] = useState("");
	const [popup, setPopup] = useState(false);
	const [popupAdd, setPopupAdd] = useState("");
	const [popupEmoji, setPopupEmoji] = useState("");
	const [popupHeading, setPopupHeading] = useState("");
	const [popupContent, setPopupContent] = useState("");
	const [fire, setFire] = useState(false);
	const [showDetails, setShowDetails] = useState(false);
	const navigate = useNavigate();

	const [rewards, setRewards] = useState([]);

	const handleClick = (e, id, name, image, price, instruction) => {
		setPopupContent(image);
		setPopupHeading(name);
		setClassChange(!classChange);
		// setPopupText("You've unlocked " + name + ". Show this card at the booth to claim.");
		setPopupText(instruction);
		// setPopupText(
		// 	(user.score >= price &&
		// 		id === "reward-one" &&
		// 		!user.rewards.reward_one.redeemed) ||
		// 		(user.score >= price &&
		// 			id === "reward-two" &&
		// 			!user.rewards.reward_two.redeemed)
		// 		? "Here is your reward. Click button for redemption"
		// 		: user.score < price
		// 			? "You need to finish other activities to unlock this reward"
		// 			: "You've already claimed your reward, congratulaitons!"
		// );
		setPopupAdd(
			(user.score >= price &&
				id === "reward-one" &&
				!user.rewards.reward_one.redeemed) ||
				(user.score >= price &&
					id === "reward-two" &&
					!user.rewards.reward_two.redeemed) ? (
				<button
					className="redeem-btn"
					onClick={async () => {
						setFire(true);
						setTimeout(() => {
							setPopup(false);
						}, 1500);
						const user_ref = await doc(db, "users", user.id);
						price === 25
							? await updateDoc(user_ref, {
								"rewards.reward_one.redeemed": true,
							})
							: await updateDoc(user_ref, {
								"rewards.reward_two.redeemed": true,
							});
						await navigate("/profile");
					}}
				>
					Redeem
				</button>
			) : (
				""
			)
		);
	};

	function onModalChange(newState) {   
		setPopup(newState);
	}

	useEffect(() => {
		const unsub = onSnapshot(
			query(collection(db, "users"), orderBy("score", "desc")),
			(snapshot) => {
				setUsers(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
			}
		);
		return () => unsub();
	}, []);



	useEffect(() => {
		if (users) {
			users.forEach((user, i) => {
				if (user.id === cookie) {
					setRating(i + 1);
					setScore(user.score);
					setUserAvatar(user.avatarUrl);
					setName(user.firstName);
					setUser(user);
				}
			});
		}
	}, [users]);

	useEffect(() => {
		if (classChange) {
			setPopup(true);
		} else {
			setPopup(false);
		}
	}, [classChange]);

	useEffect(() => {

		if (user.rewards) {
			setRewards(user.rewards);
		}
	}, [user, user.rewards]);


	return (
		<div className="rewards">
			<header>
				<div className="top">
					<ArrowLeft />
					Rewards
				</div>
			</header>
			<div className="profile-wrapper">
				{/* <div className="dash"></div> */}
				<div class="rewards-header">
					<div class="rewards-header-flex">
						{user.avatarRevealed ? (
							<div style={{
								width: '100px', margin: '0 24px 24px 24px',
								transform: 'translateY(15px)'
							}}>
								<ProgressCircleComponent
									currentStep={score}
									step={score}
									shape="shield"
									progress={score / 50}
									avatar={userAvatar}
									stroke={{ primary: '#a57af7', background: '#6946aa' }}
									style={{ margin: '0 20px' }}
								/>
							</div>
						) : (
							<div style={{ width: '100px', margin: '0 24px 24px 24px', transform: 'translateY(15px)' }}>
								<ProgressCircleComponent
									currentStep={score}
									step={score}
									shape="shield"
									progress={score / 50}
									avatar={avatar}
									stroke={{ primary: '#a57af7', background: '#6946aa' }}
									style={{ margin: '0 20px' }}
								/>
							</div>
						)}
						<div class="points-box">
							<div class="points-centered">
								<p class="points-text">{score} Points</p>
								<p class="points-caption">Reward balance</p>
							</div>

						</div>
					</div>
				</div>

				<div class="rewards-body">
					<p className="profile-titles">Available rewards </p>


					<div className="reward">
						{Object.values(rewards).map((reward, i) => {
							
							if (reward.price <= user.score) {
								const img = reward.image;
								return (
									<div
										key={i}
										className="wallet-card activity-nft"
										onClick={(e) => handleClick(e, i, reward.name, reward.image, reward.price, reward.instruction)}
										>
										<img className="emoji" src={img} />
										<div class="reward-card-right">
											<p>{reward.name}</p>
											<p class="price-in-points">{reward.price} points</p>

											{/* onClick={(e) => handleClick(e, i, reward.name, reward.price)} */}
											{/* <button className={`business-card-btn redeem-btn ${i == 0 ? "visible" : "hidden"} ${reward.redeemed ? "disabled" : "active"}`} onClick={(e) => handleClick(e, i, reward.name, reward.price)}>{reward.redeemed ? "Redeemed" : "Redeem"}</button>
 */}


										</div>
									</div>
								);
							} else {
								const img = reward.image;
								return (
									<div
										key={i}
										className="locked wallet-card activity-nft"
									>
										<img className="emoji" src={img} />
										<div class="reward-card-right">
											<p>{reward.name}</p>
											<p class="price-in-points">{reward.price} points to unlock</p>
										</div>
									</div>
								);
							}
						})}
					</div>
				</div>

			</div>
			<PopUpBio trigger={popup} onModalChange={onModalChange}>
				{
					<>
						<div
							className={`wallet-card ${!showDetails ? "" : "hide-details"}`}
							onClick={(e) => handleClick(e, "")}
						>
							{popupContent ? (
								<img className="wallet-image" src={popupContent} alt="" />
							) : (
								<div className="emoji">{popupEmoji}</div>
							)}
						</div>
						<div className={`flip-side ${!showDetails ? "" : "hide-details"}`}>
							<h2>{popupHeading} </h2>
							<div className="popup-text">{popupText}</div>
							{popupAdd}
						</div>
						<button
									className="redeem-btn"
									onClick={(e) => {
										setPopup(false)
										// code for redemption
									}}
								>
									OK
								</button>
					</>
				}
			</PopUpBio>
			<ConfettiComp fire={fire} />
			<Footer />
		</div>
	);
}
