// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// const firebaseConfig = {
// 	apiKey: "AIzaSyCXpO7REj84e-X4TCuT_FIy-05qqHr5ctw",
// 	authDomain: "lagoon-app-d1ef8.firebaseapp.com",
// 	projectId: "lagoon-app-d1ef8",
// 	storageBucket: "lagoon-app-d1ef8.appspot.com",
// 	messagingSenderId: "512491643005",
// 	appId: "1:512491643005:web:26906c82305e45f9539114",
// 	measurementId: "G-40V79QPE2E",
// };


const firebaseConfig = {
    apiKey: "AIzaSyBaJFsHGebBglHZiiv7zLGaeIfXQkuMiFg",
    authDomain: "lagoon-saas.firebaseapp.com",
    projectId: "lagoon-saas",
    storageBucket: "lagoon-saas.appspot.com",
    messagingSenderId: "1015309886894",
    appId: "1:1015309886894:web:d6fdd04f46bf4ae57f943f",
    measurementId: "G-N9SV1RJWK1"
};

/*
const firebaseConfig = {
  apiKey: "AIzaSyBAiYnQNDeJjLA4n1kcsw7VR28Dy5afMhM",
  authDomain: "test-11eca.firebaseapp.com",
  projectId: "test-11eca",
  storageBucket: "test-11eca.appspot.com",
  messagingSenderId: "655189824403",
  appId: "1:655189824403:web:60ad6781bacd4cd69e0e66",
  measurementId: "G-45EWZ0QN9H"
};
*/


// const firebaseConfig = {
// 	apiKey: "AIzaSyCXpO7REj84e-X4TCuT_FIy-05qqHr5ctw",
// 	authDomain: "lagoon-app-d1ef8.firebaseapp.com",
// 	projectId: "lagoon-app-d1ef8",
// 	storageBucket: "lagoon-app-d1ef8.appspot.com",
// 	messagingSenderId: "512491643005",
// 	appId: "1:512491643005:web:26906c82305e45f9539114",
// 	measurementId: "G-40V79QPE2E"
//   };

//   const firebaseConfig = {
// 	apiKey: "AIzaSyDI509CYDTuSH0XqFtwqzRPv2REpfn6Oio",
// 	authDomain: "lagoon-118b4.firebaseapp.com",
// 	databaseURL: "https://lagoon-118b4-default-rtdb.firebaseio.com",
// 	projectId: "lagoon-118b4",
// 	storageBucket: "lagoon-118b4.appspot.com",
// 	messagingSenderId: "720373562849",
// 	appId: "1:720373562849:web:7a68a312c15246a4d7a6a0",
// 	measurementId: "G-NJRYHM62J7"
//   };

// const firebaseConfig = {
//   apiKey: "AIzaSyA7b-9WzjZ0lTSiBRjVMvsC0edTr3k474A",
//   authDomain: "lagoon2-42506.firebaseapp.com",
//   projectId: "lagoon2-42506",
//   storageBucket: "lagoon2-42506.appspot.com",
//   messagingSenderId: "166084838558",
//   appId: "1:166084838558:web:220ed88c4046f1bb7b678f",
//   measurementId: "G-8341K1JZZH"
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);

export default db;
