import { useState, useEffect, useContext } from "react";
import { UserContext } from "./User";
import { doc, updateDoc, addDoc, collection } from "firebase/firestore";
import db from "../firebase";
import { useForm } from "react-hook-form";
import Footer from "./Footer";
import ArrowLeft from "./ArrowLeft";
import TaskCard from "./TaskCard";
import Cookies from "js-cookie";
import useScore from "../hooks/useScore";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import useAnalytics from "../hooks/useAnalytics";
import ConfettiComp from "./ConfettiComp";
import PopUpMessage from "./PopUpMessage";
import PopUpBio from "./PopUpBio";
import { DataContext } from "./DataContext";
import useTexts from "../hooks/useTexts";

export default function Bio() {

	const { DATA, setData } = useContext(DataContext);

	const location = useLocation();
	const { packet_id } = location.state;
	const { activity_i } = useParams();
	const activity_index = activity_i - 1;
	const packet_index = parseInt(packet_id.replace(/packet_/, '') - 1);
	const { points } = location.state;

	const publicDomains = [
		"gmail",
		"yahoo",
		"outlook",
		"inbox",
		"icloud",
		"mail",
		"aol",
		"protonmail",
	];
	const { user, setUser } = useContext(UserContext);
	const id = Cookies.get().id;
	const {
		handleSubmit,
		register,
		formState: { errors },
	} = useForm();
	const [email, setEmail] = useState("");
	const [input, setInput] = useState("");
	const [popUp, setPopUp] = useState(false);
	const [popup, setPopup] = useState("");
	const [updateScore1] = useScore(points, id);
	const [updateScore2] = useScore(points, id);
	const navigate = useNavigate();
	const [addAnalytics] = useAnalytics("corporate email", id, {});
	const [fire, setFire] = useState(false);
	const [package_points, setPackage_points] = useState(0);


	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (user.progress && user.project_id && user.progress[user.project_id]) {
			setPackage_points(user.progress[user.project_id]['packets'][packet_index].package_points);
		}
	}, [user, user.progress, user.project_id]);

	//=======================================
	var texts = {
		email: {
			subject: 'Thank you for completing your profile, #username#!',
			html: '\
		    <p>Hi, #username#!</p><br>\
		    <p>Thank you for completing your profile. <br>To receive the points, input this code when prompted <h3>#activation_code#</h3>.<br/><br/>Feel free to reach out to me by responding to this email or to Misha (misha@fictiontribe.com) with any questions.</p>',
		},
		sms: {
			text: 'Hi, #username#! Thank you for completing your profile. Here is your activation code - #activation_code#',
		}
	};
	var templates = [
		{
			code: '#username#',
			value: user.firstName,
		},
		{
			code: '#activation_code#',
			value: user.activationCode,
		}
	];
	texts = useTexts('completing_profile', templates, texts);
	//console.log(texts);
	//=======================================

	const sendEmail = async (cEmail) => {

		await addDoc(collection(db, "mail"), {
			to: cEmail,
			message: {
				subject: texts['email']['subject'],
				html: texts['email']['html'],
			},
		});
	};

	const sendText = async (phoneNumber) => {
		const newText = addDoc(collection(db, "sms"), {
			to: phoneNumber,
			body: texts['sms']['text'],
		}).then((docRef) => {
			console.log(docRef);
		});
	};

	function onModalChange(newState) {
		setPopup(newState);
	}

	const validEmailSubmitted = async (cEmail, phoneNumber) => {
		sendEmail(cEmail);
		sendText(phoneNumber);
		// setPopup("Enter your activation code from the text message / email");
		setPopUp("Thank you for completing your profile!")
		setPopUp(true);
		// updateScore1();
		await addAnalytics();
		setFire(true);
	};
	const invalidEmailSubmitted = async () => {
		sendEmail();
		setPopup("Nice job - you've definitely earned some points!");
		setPopUp(true);
		await updateScore2();
		await addAnalytics();
		setFire(true);
	};

	const onSubmit = async (data) => {
		const user_ref = await doc(db, "users", Cookies.get().id);
		const name = user_ref.firstName;

		user.progress[user.project_id]['packets'][packet_index].activities[activity_index].completed = true;
		user.progress[user.project_id]['packets'][packet_index].package_points = parseInt(package_points) + parseInt(points);

		if (DATA['PACKETS'][packet_index].activities[activity_index]['reward']) {

			let reward = DATA['PACKETS'][packet_index].activities[activity_index]['reward'];

			if (reward.name != '') {
				user.rewards.push({
					name: reward.name,
					price: reward.price,
					image: reward.image,
					redeemed: false,
					instruction: reward?.instruction,
				});
			}
		}

		user.setprogress = false;
		setUser(Object.assign({}, user));

		await updateDoc(user_ref, {
			progress: user.progress,
			companyName: data.company,
			corporateEmail: data.email,
			firstName: data.firstName ? data.firstName : user.firstName,
			lastName: data.lastName,
			// phone: data.phone,
			profileComplete: true,
			// rewards: user.rewards,
		});

		Cookies.set('profileComplete', true);
		const emailDomain = data.email.split("@")[1].split(".")[0];
		if (!publicDomains.includes(emailDomain)) {
			validEmailSubmitted(data.email, data.phone);
		} else {
			invalidEmailSubmitted();
		}
	};

	const closeModal = async () => {
		// if (input === user.activationCode) {
		setFire(true);
		updateScore1();
		// if (user.phone) {
		// 	await addDoc(collection(db, "sms"), {
		// 		to: user.phone,
		// 		body: `You have now unlocked additional activities in Lagoon. More points await! Explore your new activities, including augmented reality, by clicking this link https://app.lagoon.live/#/?unlock=9090909d9kz `,
		// 	});
		// }
		navigate("/");
		// } else {
		// 	document.querySelector("#error").innerHTML =
		// 		"Looks like your code is incorrect - please, try again!";
		// }
	};
	// useEffect(() => {
	// 	if (fire) {
	// 		setTimeout(() => {
	// 			navigate("/");
	// 		}, 2000);
	// 	}
	// }, [fire]);

	return (
		<div className="bio">
			<header>
				<div className="top">
					<ArrowLeft />
					Profile
				</div>
			</header>
			<ConfettiComp fire={fire} />
			<TaskCard
				taskDescription={"Enter additional information to get more points"}
			// buttonText={"Get my points"}
			// handleClick={handleClick}
			/>
			<form className="email-form">
				<input {...register("firstName")} placeholder={user.firstName} />
				<p>{errors.firstName?.message}</p>
				<input
					{...register("lastName", { required: "Your last name is required" })}
					placeholder="Last Name"
				/>
				<p>{errors.lastName?.message}</p>
				<input
					{...register("email", {
						required: "Enter your corporate email, please",
						pattern: {
							value:
								/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
							message: "Invalid email address",
						},
					})}
					placeholder="Corporate email"
				/>
				<p>{errors.email?.message}</p>
				<input
					{...register("company", {
						required: "Please enter your company name",
					})}
					placeholder="Company name"
				/>
				{/* <input {...register("phone", { required: "Please enter your phone", })} placeholder="Your phone number"/> */}
				{/* <p>{errors.phone?.message}</p> */}
				<button onClick={handleSubmit((data) => onSubmit(data))}>
					Complete Profile
				</button>
			</form>
			<PopUpBio trigger={popUp} closeModal={closeModal}>
				{
					<div>
						<h2>Thank you for your response!</h2>
						<p>{popup}</p>
						{/* <input
							id="activation-input"
							name="validation"
							placeholder="Input your code here"
							onChange={(e) => setInput(e.target.value)}
						/> */}
						<p id="error"></p>
					</div>
				}
			</PopUpBio>
			<Footer />
		</div>
	);
}
