import react, { useContext, useEffect,useCallback,useRef, useState } from "react";
import Activities from "./Activities";
import { useSearchParams, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Packets from "./Packets";
import Profile from "./Profile";
import { UserContext } from "./User";
// import { doc, addDoc, updateDoc, onSnapshot } from "firebase/firestore";
import { doc, onSnapshot, addDoc, updateDoc, collection } from "@firebase/firestore";
import db from "../firebase";
import Cookies from "js-cookie";
import ConfettiComp from "./ConfettiComp";
import PacketConfetti from "./PacketConfetti";
import PopUpMessage from "./PopUpMessage";
import InAppNotification from "./InAppNotification";
import useScore from "../hooks/useScore";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { DataContext } from "./DataContext";
import { useParams, useLocation } from 'react-router-dom';
import { DefaultData, ProcessData } from "./Data";
import Projects from "./Projects";
import useAnalytics from "../hooks/useAnalytics";

gsap.registerPlugin(ScrollTrigger);

export default function DashboardProject(props) {

    console.log('dashboard');

    const userId = Cookies.get().id;

    const navigate = useNavigate();

    const [projectId, setProjectId] = useState(false);
    const [search, setSearch] = useState(false);

    //console.log('dashboard');

    const location = useLocation();
    const { code } = useParams();
    //console.log('code='+code);

    const { user, setUser } = useContext(UserContext);

    const { DATA, setDATA } = useContext(DataContext);


    //===============================

    useEffect(() => {

        if (DATA.newProject) {

            console.log('redirect to auth');

            Cookies.remove("id");
            Cookies.remove("lastLogin");
            Cookies.remove("didShowProgressNotificationModuleOne");
            Cookies.remove("showProgressNotificationModuleOne");
            Cookies.remove("profileComplete");
            Cookies.remove("giftCardEarnedShow");

            setDATA({});

            navigate('/signup');
        }

    }, [DATA.newProject]);


    //*** get project by url ***

    /*
    useEffect(() => {

        if (!projectId  && !DATA['existingProject'] ){

            getProject(code);
        }

    }, [user, code]);

    */


    //=========================

    const [popUp, setPopUp] = useState(false);
    const [notification, setNotification] = useState(false);
    const [fire, setFire] = useState(false);
    const [packetFire, setPacketFire] = useState(false);
    const [popUpMessage, setPopUpMessage] = useState(null);
    const [notificationMessage, setNotificationMessage] = useState(null);
    const [notificationButtonText, setNotificationButtonText] = useState(null);
    const [notificationButtonUrl, setNotificationButtonUrl] = useState(null);
    const [searchParams] = useSearchParams();

    let unlockParam = searchParams.get("unlock");
    if (!unlockParam && Cookies.get().unlock)
        unlockParam = Cookies.get().unlock;

    //console.log('DATA===');
    //console.log(DATA);
    //console.log('unlockParam='+unlockParam);

    let confirmParam = searchParams.get("confirm");
    const [updateScore] = useScore(10, Cookies.get().id);
    const [updateScoreNew] = useScore(5, Cookies.get().id);
    const [pointsModal, setPointsModal] = useState(false);
    const [moduleComplete, setModuleComplete] = useState(false);
    const [nextModuleUnlockLink, setNextModuleUnlockLink] = useState(null);
    const [giftCardUnlocked, setGiftCardUnlocked] = useState(false);

    //=======================================

    const closeModal = () => {
        props.closeModal(false);
    };

    const closeNotification = () => {
        props.closeNotification(false);
    };

    const closePage = () => {
        navigate("/");
    };

    function onModalChange(newState) {
        setPopUp(newState);
    }

    function onNotificationChange(newState) {
        setNotification(newState);
    }

    const completeProfile = () => {

        if (user.progress && typeof (DATA) !== 'undefined' && user.progress[DATA.ID] && user.progress[DATA.ID]['packets'] && !DATA['defaultData']) {

            if (user.progress[DATA.ID].bio) {

                navigate(user.progress[DATA.ID].bio.link);
                Cookies.set("didShowProgressNotificationModuleOne", true);

            }
        }
    };

    let tl = gsap.timeline({
        // yes, we can add it to an entire timeline!
        scrollTrigger: {
            trigger: ".profile",
            pin: false, // pin the trigger element while active
            start: "top top", // when the top of the trigger hits the top of the viewport
            end: "+=1000", // end after scrolling 500px beyond the start
            scrub: true, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
        },
    });

    // tl.call(document.querySelector('.greetings').)
    // tl.to(".greetings", { position: "fixed", top: "0", width: "100%", background: "rgba(0,0,0,0.9)" });

    /*
    const updateUserProgress = async (progress) => {
        const id = Cookies.get("id");
        const _ref_users = await doc(db, "users", id);
        await updateDoc(_ref_users, {
            progress: progress,
        });
    };
    */

    const updateUserProgress = async (progress) => {

        const _ref_users = await doc(db, "users", userId);
        await updateDoc(_ref_users, {
            progress: progress,
        });
    };

    const smsSentRef = useRef(false);
    const [sentNotifications, setSentNotifications] = useState(new Set());

    const sendSMS = async (notification, index) => {
        const sentKey = `notificationSent_${index}`;
        const alreadySent = localStorage.getItem(sentKey);
    
        if (alreadySent) return; // Stop if already sent
    
        try {
            const docRef = await addDoc(collection(db, "sms"), {
                to: user.phone,
                body: notification.sms_content,
            });
            console.log("SMS sent with ID: ", docRef.id);
            localStorage.setItem(sentKey, 'true');
        } catch (error) {
            console.error("Error sending SMS: ", error);
        }
    };
    
    const processNotifications = () => {
        if (DATA && DATA['inAppNotifications']) {
            DATA['inAppNotifications'].forEach((notification, index) => {
                const notificationShownKey = `notificationShown_${index}`;
                if (user.score >= notification.points_threshold && !localStorage.getItem(notificationShownKey)) {
                    // Logic to show in-app notification
                    localStorage.setItem(notificationShownKey, 'true');
                    
                    if (notification.send_sms && notification.sms_content) {
                        sendSMS(notification, index);
                    }
                }
            });
        }
    };

    useEffect(() => {

        // if (
        //     Cookies.get().showProgressNotificationModuleOne &&
        //     Cookies.get().didShowProgressNotificationModuleOne == "false" &&
        //     Cookies.get().profileComplete != "true"
        // ) {
        //     setPointsModal(true);
        //     setPopUpMessage(
        //         `You are 10 pts away from your first reward. Complete your profile to claim.`
        //     );
        //     setPopUp(true);
        //     setFire(true);
        // }
        // if (!Cookies.get().giftCardEarnedShow && user.score >= 25) {
        //     setGiftCardUnlocked(true);
        //     setPopUpMessage("You just unlocked your first reward");
        //     setPopUp(true);
        //     setFire(true);
        //     Cookies.set("giftCardEarnedShow", true);
        // }

        // const processNotifications = async () => {
        //     if (DATA && DATA['inAppNotifications']) {
        //         for (const [index, notification] of DATA['inAppNotifications'].entries()) {
        //             const notificationShownKey = `notificationShown_${index}`;
        //             if (user.score >= notification.points_threshold && !Cookies.get(notificationShownKey)) {
        //                 setNotification(false);
        //                 setFire(false);

        //                 setTimeout(() => {
        //                     setNotificationMessage(notification.notification_text);
        //                     if (notification.button_text) {
        //                         setNotificationButtonText(notification.button_text);
        //                     }
        //                     if (notification.button_url) {
        //                         setNotificationButtonUrl(notification.button_url);
        //                     }
        //                     setNotification(true);
        //                     setFire(true);

        //                     Cookies.set(notificationShownKey, true);
        //                 }, 1000);

        //                 if (notification.send_sms && notification.sms_content) {
        //                     await sendSMS(notification, index);
        //                     Cookies.set(notificationShownKey, true);
        //                 }
        //             }
        //         }
        //     }
        // };

        processNotifications();

        if (user.progress && typeof (DATA) !== 'undefined') {

            if (user.progress && user.progress[DATA.ID] && user.progress[DATA.ID]['packets'] && !DATA['defaultData']) {

                if (confirmParam === Cookies.get().id) {


                    if (user.progress[DATA.ID].bio) {

                        let packet_index = user.progress[DATA.ID].bio.packet_index;
                        let activity_index = user.progress[DATA.ID].bio.activity_index;
                        if (!user.progress[DATA.ID]['packets'][packet_index]['activities'][activity_index].completed) {

                            user.progress[DATA.ID]['packets'][packet_index]['activities'][activity_index].completed = true;
                            updateUserProgress(user.progress);
                            updateScore(DATA['PACKETS'][packet_index]['activities'][activity_index].points, Cookies.get().id);
                        }

                    }

                }

                //==========================

                Object.values(user.progress[DATA.ID]['packets']).forEach((packet, packet_index) => {


                    if (packet_index < user.progress[DATA.ID]['packets'].length - 1) {
                        const allActivitiesCompleted = packet.activities && packet.activities.every(activity => activity.completed);

                        if (allActivitiesCompleted) {
                            // Mark the packet as completed
                            user.progress[DATA.ID]['packets'][packet_index].packet_completed = true;
                    
                            // Unique key for module completion notification
                            const moduleCompletionShownKey = `moduleCompletionShown_${packet_index}`;
                    
                            if (!Cookies.get(moduleCompletionShownKey)) {
                                // Show notification for module completion
                                setTimeout(() => {
                                    let completionMessage = `You have completed the Module: ${packet.name}. Tap below to unlock the next module.`;
                                    setModuleComplete(true);
                                    setNextModuleUnlockLink('/?unlock=packet_' + (packet_index + 1));
                                    setPopUpMessage(completionMessage);
                                    setPopUp(true);
                                    setPacketFire(true);
                                    Cookies.set(moduleCompletionShownKey, true);
                                }, 1500)
    
                                // Mark as shown in cookies
                            }
                        }
                    }


                    //debugger;

                    // setPacketFire(true);

                    //console.log('unlock2');
                    //console.log(packet.packet+' === '+unlockParam);

                    if (packet.packet === unlockParam) {

                        // Unique key for module notification
                        const moduleNotificationShownKey = `moduleNotificationShown_${packet_index}`;

                        // TEMP HARDCODE FOR DEMO
                        // UNLOCK MODULE 0
                        if (packet_index != 0 && !user.progress[DATA.ID]['packets'][packet_index].packet_activated) {
                            user.progress[DATA.ID]['packets'][0].packet_activated = true;
                            user.progress[DATA.ID]['packets'][0].badge.is_unlocked = true;
                        }

                        let temp = DATA;
                        temp['unlock'] = packet_index;
                        setDATA(temp);

                        user.progress[user.project_id].activeBadge = packet_index;


                        console.log('unlock param ' + unlockParam);

                        user.progress[DATA.ID]['packets'][packet_index].packet_activated = true;
                        user.progress[DATA.ID]['packets'][packet_index].badge.is_unlocked = true;

                        // if (packet_index > 0)
                            // user.progress[DATA.ID]['packets'][packet_index - 1].packet_completed = true;

                        updateUserProgress(user.progress);

                        if (!Cookies.get(moduleNotificationShownKey)) {
                            // HARD CODED - fix later
                            if (window.umami) {
                                window.umami.track("Unlock module: " + unlockParam);
                            } else {
                                console.error('Umami not loaded');
                            }
                            let unlockMessage = "You just unlocked Module " + DATA['PACKETS'][packet_index]['name'] + "!";
                            if (user.score === 0) {
                                updateScoreNew(5, Cookies.get().id);
                                unlockMessage += " Here's 5 points to get you started!";
                            }

                            setPopUpMessage(unlockMessage);
                            setPopUp(true);
                            setFire(true);

                            Cookies.set(moduleNotificationShownKey, true);
                        }

                        Cookies.remove('unlock');
                    }
                });
            }
        }

    }, [user, DATA]);


    //console.log('dashboard');

    //console.log(DATA);

    /*
    useEffect(() => {
        console.log(DATA);
      }, [DATA]);
      */

    return (

        <div className="dashboard">

            {DATA && DATA.status == 'draft' ?

                <div className="disabled-project">
                    This project is disabled
                </div>
                :
                <>

                    <ConfettiComp fire={fire} />
                    <PacketConfetti fire={packetFire} />
                    <PopUpMessage onModalChange={onModalChange} hideButton={moduleComplete} trigger={popUp}>
                        {
                            <div>
                                <h2>Congratulations!</h2>
                                <p>{popUpMessage}</p>
                                {pointsModal && (
                                    <button
                                        style={{ width: "200px", marginBottom: "-35px" }}
                                        className="bg-blue-400 rounded px-2 py-1 hover:bg-blue-500 text-center text-white font-medium"
                                        onClick={completeProfile}
                                    >
                                        Complete my profile
                                    </button>
                                )}
                                {moduleComplete && (
                                    <button
                                        style={{ width: "200px", marginBottom: "-35px" }}
                                        className="bg-blue-400 rounded px-2 py-1 hover:bg-blue-500 text-center text-white font-medium"
                                        onClick={() => navigate(nextModuleUnlockLink)}
                                    >
                                        Unlock next module!
                                    </button>
                                )}
                                {giftCardUnlocked && (
                                    <button
                                        style={{ width: "200px", marginBottom: "-35px" }}
                                        className="bg-blue-400 rounded px-2 py-1 hover:bg-blue-500 text-center text-white font-medium"
                                        onClick={() => navigate("/rewards")}
                                    >
                                        Check my Rewards
                                    </button>
                                )}
                            </div>
                        }
                    </PopUpMessage>
                    <InAppNotification
                        onModalChange={onNotificationChange}
                        trigger={notification}
                        buttonText={notificationButtonText}
                        navigateTo={notificationButtonUrl}
                    >
                        <div>
                            {/* <h2>In-App Notification!</h2> */}
                            <p>{notificationMessage}</p>
                            {pointsModal && (
                                <button
                                    style={{ width: "200px", marginBottom: "-35px" }}
                                    className="bg-blue-400 rounded px-2 py-1 hover:bg-blue-500 text-center text-white font-medium"
                                    onClick={completeProfile}
                                >
                                    Complete my profile
                                </button>
                            )}
                        </div>
                    </InAppNotification>

                    {/* <button onClick={() => setPopUp(true)}>TEST POPUP</button>
                    <button onClick={() => setNotification(true)}>TEST Notification</button> */}

                    <Profile showAllBadges={true} />

                    <Packets />

                    <Footer />
                </>
            }

        </div>
    );
}
