import React, { useEffect, useState, PureComponent } from 'react';


import db from "../firebase";
import { doc, getDocs, collection, onSnapshot, setDoc, getDoc, query, where, order, orderBy } from "firebase/firestore";

export default function AirdropsList ({airdrops}) {

	const [AirdropsList, setAirdropsList] = useState(airdrops);

    return (


		<div className="airdropsList">

			{AirdropsList.length > 0 ?

				AirdropsList.map( (airdrop, airdropIndex) =>

					<div className="airdrop__item" key={airdropIndex}>

						{/* <div className="airdrop__left"> */}
							<div className="airdrop__image"><img src={airdrop.image} /></div>
						{/* </div> */}
						{/* <div className="airdrop__right">
							<div className="airdrop__name">{airdrop.name}</div>
							<div className="airdrop__description">{airdrop.description}</div>
						</div> */}


					</div>

				)

				:

				<div className="empty-airdrops">
					No airdrops yet
				</div>

			}


		</div>


    )

}

