import React, { useEffect, useState } from "react";
import db from "../firebase";
import {
	onSnapshot,
	collection,
	query,
	orderBy,
	limit,
} from "firebase/firestore";

const Board = () => {
	const [users, setUsers] = useState([]);

	useEffect(() => {
		const unsub = onSnapshot(
			query(collection(db, "users"), orderBy("score", "desc"), limit(3)),
			(snapshot) => {
				setUsers(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
			}
		);
		return () => unsub();
	}, []);

	return (
		<>
			<svg
				id="dot"
				width="8"
				height="8"
				viewBox="0 0 8 8"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle cx="4" cy="4" r="4" fill="#41295a" />
			</svg>
			<ol className="board">
				{users.map((user) => (
					<li key={user.id}>
						<div class="name-card">
							<div class="main">
								<img
									class="avatar"
									src="https://picsum.photos/44"
									alt="avatar"
								/>
								<div class="info">
									<p class="name">
										{user.firstName} {user.lastName}
									</p>
									<p class="points">{user.score}</p>
								</div>
								<br />
								<span className="compid">{user.companyId}</span>
							</div>
						</div>
					</li>
				))}
			</ol>
		</>
	);
};

export default Board;
