import { set } from "firebase/database";
import { React, useState, useEffect, useContext } from "react";
// import { ReactComponent as LockIcon } from "../assets/B1.svg";
// import { ReactComponent as ReactLogo3 } from "../assets/module-2.png";
import { DataContext } from "./DataContext";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css'

import SwiperCore, { Navigation } from 'swiper/core';

import Badge from "./Badge";
import { UserContext } from "./User";

export default function Badges({badges}) {

	const { user, setUser } = useContext(UserContext);

	SwiperCore.use([Navigation]);

	const [displayBadges, setDisplayBadges] = useState(badges);
	const [displayActivities, setDisplayActivities] = useState(false);
	const {DATA, setData} = useContext(DataContext);

	const [activeIndex, setActiveIndex] = useState(0);

	if (user.progress && user.progress[user.project_id] && user.progress[user.project_id].activeBadge > 0 && user.progress[user.project_id].activeBadge <= user.progress[user.project_id].packets.length && user.progress[user.project_id].activeBadge != activeIndex){
		setActiveIndex(user.progress[user.project_id].activeBadge);
	}

	useEffect(() => {
		if (DATA['unlock'])
			setActiveIndex(DATA['unlock']);

	}, [DATA, DATA['unlock']]);



	console.log('badges');
	console.log(badges);
	console.log(DATA);


	const [showSlider, setShowSlider] = useState(false);
	useEffect(() => {

		if (DATA.PACKETS && DATA.PACKETS.length > 3)
			setShowSlider(true);

	}, [DATA, DATA.PACKETS]);


	return (
		<>

			{ showSlider ?

				<div className="slider-badges">

					<Swiper
						spaceBetween={0}
						slidesPerView={3}
						navigation={true}
						modules={[Navigation]}
						>

						{badges &&

							DATA.PACKETS.map( (packet, packetIndex) =>

								<SwiperSlide>
									<Badge packetIndex={packetIndex} activeIndex={activeIndex} badges={badges} packet={packet} />
								</SwiperSlide>
							)
						}

					</Swiper>

				</div>

				:

				<div className="row badges">

					{badges &&

						DATA.PACKETS.map( (packet, packetIndex) =>

							<Badge packetIndex={packetIndex} activeIndex={activeIndex} badges={badges} packet={packet} />
						)
					}

				</div>

			}

		</>
	);
}
