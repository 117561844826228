import { React, useEffect, useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { collection, addDoc, onSnapshot, doc } from "firebase/firestore";
import db from "../firebase";
import Cookies from "js-cookie";
import { Navigate, useNavigate } from "react-router-dom";
import { UserContext } from "./User";
import { DataContext } from "./DataContext";
import { ReactComponent as LagoonLogo } from "../assets/logo.svg";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export default function SignIn() {
	const { DATA, setDATA } = useContext(DataContext);

	const {
		register,
		reset,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm();
	const { user, setUser } = useContext(UserContext);
	const [users, setUsers] = useState([]);
	const [phone, setPhone] = useState("");
	const navigate = useNavigate();
	const [userId, setUserId] = useState(false);
	const [fingerprint, setFingerprint] = useState("");
	const [popupVisible, setPopupVisible] = useState(false);
	const [popupMessage, setPopupMessage] = useState("");

	const cookieOptions = { expires: 90 };

	useEffect(() => {
		if (users.length === 0) {
			onSnapshot(collection(db, "users"), (snapshot) =>
				setUsers(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
			);
		}
	}, [users]);

	const generateFingerprint = async (ipAddress) => {
		try {
			const fp = await FingerprintJS.load();
			const { visitorId } = await fp.get();

			// Prepend IP address (without periods) to the fingerprint if available
			const finalFingerprint = ipAddress
				? ipAddress.replace(/\./g, "") + visitorId
				: visitorId;
			console.log("Generated fingerprint:", finalFingerprint);

			return finalFingerprint;
		} catch (error) {
			console.log("Error generating fingerprint:", error);
			return null;
		}
	};

	const updateFingerprintState = (finalFingerprint) => {
		if (finalFingerprint !== null) {
			console.log("Setting fingerprint in state:", finalFingerprint);
			setFingerprint(finalFingerprint);
			Cookies.set("fingerprint", finalFingerprint, cookieOptions); // Store fingerprint in cookies
		} else {
			console.log("Setting empty fingerprint in state.");
			setFingerprint("");
			Cookies.remove("fingerprint"); // Remove the fingerprint cookie
		}
	};

	useEffect(() => {
		if (!fingerprint) {
			console.log("Generating fingerprint...");

			const fetchIpAddress = () => {
				console.log("Fetching IP address...");
				return fetch("https://api.ipify.org?format=json")
					.then((response) => response.json())
					.then((data) => {
						const ipAddress = data.ip;
						console.log("Fetched IP address:", ipAddress);
						return ipAddress;
					})
					.catch((error) => {
						console.log("Error fetching IP address:", error);
						return null; // Return null in case of any error
					});
			};

			fetchIpAddress()
				.then((ipAddress) => generateFingerprint(ipAddress))
				.then((finalFingerprint) => updateFingerprintState(finalFingerprint))
				.catch((error) => {
					console.log("Error in fingerprint generation:", error);
					updateFingerprintState(null); // Handle error case
				});
		} else {
			console.log("Fingerprint already set:", fingerprint);
		}
	}, [fingerprint]);

	const signUpUser = async (data) => {

		setDATA({auth: true});

		data.phone = '+' + data.phone;

		console.log(data);

		const existingUser = users.find((u) => u.phone === data.phone);

		if (existingUser) {
			// User with the same phone number already exists
			setPopupMessage(
				"Looks like somebody with the same phone number is already registered. Try logging in instead."
			);
			setPopupVisible(true);
			return; // Don't proceed with registration
		}

		let isUser = false;

		if (users.length !== 0) {
			users.forEach((user) => {
				if (user.phone === data.phone) {
					Cookies.set("id", user.id, cookieOptions);
					Cookies.set("lastLogin", Date.now(), cookieOptions);
					Cookies.set("phoneNumber", user.phone, cookieOptions);
					setUser(user);
					isUser = true;
				}
			});
		}

		if (!isUser) {

			function generateUniqueRandomNumber(users, avatarsTotal) {
				const existingNumbers = new Set(users.map(user => user.randomGiftNum));
				let attempts = 0;
			
				while (attempts < 3) {
					let randomNumber = Math.floor(Math.random() * (parseInt(avatarsTotal) + 1));
					if (!existingNumbers.has(randomNumber)) {
						return randomNumber;
					}
					attempts++;
				}
			
				// Fallback: return a random number even if it's not unique
				return Math.floor(Math.random() * (parseInt(avatarsTotal) + 1));
			}
			
			let randomGiftNum = generateUniqueRandomNumber(users, avatarsTotal);


			// OLD LOGIC FOR RANDOM GIFT NUMBER
			// let randomGiftNum =
			// 	users.length +
			// 	Math.floor(Math.random() * 25) +
			// 	Math.floor(Math.random() * 99) +
			// 	Math.floor(Math.random() * 30);
			// randomGiftNum =
			// 	randomGiftNum +
			// 	Math.floor(Math.random() * 5) +
			// 	Math.pow(Math.floor(Math.random() * 5), 2) -
			// 	Math.floor(Math.random() * 10);
			// if (randomGiftNum >= 300) {
			// 	randomGiftNum = users.length;
			// 	if (randomGiftNum >= 300) {
			// 		randomGiftNum -= Math.floor(Math.random() * 99);
			// 	}
			// }

			const fields = {
				firstName: data.firstName,
				lastName: "",
				phone: data.phone,
				profileComplete: false,
				score: 0,
				fingerprint: fingerprint,
				progress: {},
				rewards: [],
				setprogress: false,
				logoUrl:
					"https://firebasestorage.googleapis.com/v0/b/lagoon-118b4.appspot.com/o/logos%2F" +
					randomGiftNum +
					".png?alt=media",
				avatarUrl:
					avatarsBucket + avatarsPrefix +
					randomGiftNum +
					"." + avatarsExt,
				avatarRevealed: "",
				logoRevealed: false,
				drinkRedeemed: false,
				lastLogin: Date.now(),
				activationCode:
					Date.now()
						.toString()
						.substring(Date.now().toString().length - 4) +
					Math.floor(Math.random() * 100),
				randomGiftNum: randomGiftNum,
				drinkCode:
					data.firstName +
					Date.now()
						.toString()
						.substring(Date.now().toString().length - 4),
			};
			const newUser = await addDoc(collection(db, "users"), fields);
			Cookies.set("id", newUser.id, cookieOptions); // Set the 'id' cookie for the new user

			// Remove older cookies
			const allCookies = Cookies.get();
			Object.keys(allCookies).forEach(cookieName => {
				if (cookieName.startsWith('notificationShown_')) {
					Cookies.remove(cookieName);
				}
			});
			Cookies.set("lastLogin", Date.now(), cookieOptions);
			Cookies.set("phoneNumber", data.phone, cookieOptions);
			setUser(fields);
		}
	};

	useEffect(() => {
		if (Cookies.get("id") && Cookies.get("phoneNumber")) {
			// Check if both cookies exist
			const userIdCookie = Cookies.get("id");
			const phoneNumberCookie = Cookies.get("phoneNumber");
			const matchingUser = users.find(
				(user) => user.id === userIdCookie && user.phone === phoneNumberCookie
			);
			if (matchingUser) {
				setUser(matchingUser);
			}
		}
	}, [users]);

	const [Logo, setLogo] = useState("");

	const [registrationConsent, setRegistrationConsent] = useState("I consent to receive promotional communications (which may include phone, email, and social). I understand I may proactively opt out of communications at any time.");
	const [registrationAgree, setRegistrationAgree] = useState("By clicking submit, I agree to the use of my personal information in accordance with Privacy Policy.");

	const [avatarsBucket, setAvatarsBucket] = useState('https://r2.lagoon.live/');
	const [avatarsTotal, setAvatarsTotal] = useState(6000);
	const [avatarsPrefix, setAvatarsPrefix] = useState('fortinet-');
	const [avatarsExt, setAvatarsExt] = useState('jpg');

	useEffect(() => {

		if (Cookies.get("projectId")) {

			let projectId = Cookies.get("projectId");
			onSnapshot(doc(db, "projects", projectId), (doc) => {

				let project = doc.data();

				if (project) {
					// NOW CAN HIDE THE LOADER
					document.querySelector('.loader').classList.add('loaded');

					if (project["ui_texts"]) {
						if (project["ui_texts"]["registration_consent"]) {
							if (project["ui_texts"]["registration_consent"]["value"]["text"]) {
								setRegistrationConsent(
									project["ui_texts"]["registration_consent"]["value"]["text"]
								);
							}
						}
						if (project["ui_texts"]["registration_agree"]) {
							if (project["ui_texts"]["registration_agree"]["value"]["text"]) {
								setRegistrationAgree(
									project["ui_texts"]["registration_agree"]["value"]["text"]
								);
							}
						}
					}

					if (project["avatars"]) {
						if (project["avatars"]["avatars_total"]) {
							setAvatarsTotal(project["avatars"]["avatars_total"]["value"]["value"])
						}
						if (project["avatars"]["avatars_bucket"]) {
							setAvatarsBucket(project["avatars"]["avatars_bucket"]["value"]["value"])
						}
						if (project["avatars"]["avatars_prefix"]) {
							setAvatarsPrefix(project["avatars"]["avatars_prefix"]["value"]["value"])
						}
						if (project["avatars"]["avatars_ext"]) {
							setAvatarsExt(project["avatars"]["avatars_ext"]["value"]["value"])
						}
					}
					

					if (typeof project["logo"] !== "undefined" && project["logo"] != "")
						setLogo(project["logo"]);
					root?.style.setProperty("--background", project['COLORS'][1]['color']);
					root?.style.setProperty("--buttons_background", project['COLORS'][9]['color']);
					root?.style.setProperty("--buttons_color", project['COLORS'][10]['color']);
				}
			});
		}
	}, []);

	const [url, setUrl] = useState('/');
	useEffect(() => {
		let url = "/";
		if (DATA && DATA['projectId'] && typeof (DATA['projectId']) !== 'undefined') {
			setUrl('/' + DATA['projectId'] + '/');
			let temp = DATA;
			temp['auth'] = true;
			setDATA(temp);
		}
	}, [DATA, DATA['projectId']]);

	// Check if the user is already authenticated
	const isAuthenticated = Cookies.get("id") || userId;

	return isAuthenticated ? (
		<div>
			<Navigate to={url} />
		</div>
	) : (
		<div className="signUp">
			{Logo ? (
				<img className="lagoon-logo" src={Logo} />
			) : (
				<LagoonLogo className="lagoon-logo" />
			)}

			<h2 id="signin-header">Join our world</h2>
			<form id="signin-form">
				<input
					{...register("firstName", { required: "Your name is required" })}
					placeholder="Your Name"
				/>
				<p>{errors.firstName?.message}</p>

				{/*
				<input
					{...register("phone", {
						required: "Please enter your phone",
					})}
					placeholder="phone"
				/>
				*/}

				<Controller
					control={control}
					{...register("phone", {
						required: "Please enter your phone",
						pattern: {
							value:
							/^[0-9]{6,20}$/,
							message: "Invalid phone",
						},
						}
					)}
					render={({ field }) => (
						<PhoneInput
							{...field}
							country={'us'}
						/>
					)}
				/>
				<p>{errors.phone?.message}</p>

				<label className="registration_consent">
					<input
						type="checkbox"
						{...register("registration_consent", {
							required: "Please consent to receive promotional communications",
						})}
					/>
					{registrationConsent}
				</label>
				<p>{errors.registration_consent?.message}</p>

				<button
					onClick={handleSubmit((data) => {
						signUpUser(data);
					})}
					type="submit"
				>
					Join
				</button>

				<div className="registration_agree">{registrationAgree}</div>

				<button class="no-button" onClick={() => navigate("/signup1")}>
					I already have an account
				</button>
			</form>

			{/* Popup for duplicate phone number */}
			{popupVisible && (
				<div className="popup">
					<p>{popupMessage}</p>
					<button onClick={() => setPopupVisible(false)}>Close</button>
				</div>
			)}
		</div>
	);
}
