import React, { useContext } from "react";
import { Route, Navigate, useNavigate } from "react-router";
import { UserContext } from "./User";
import Cookies from "js-cookie";
import { updateDoc } from "firebase/firestore";
import { async } from "@firebase/util";
import { useParams } from 'react-router-dom';
import { DataContext } from "./DataContext";
// import Auth from "./use-auth";
import { useSearchParams } from "react-router-dom";

export default function ProtectedRoute({ children }) {

	const { DATA, setDATA } = useContext(DataContext);

	//console.log('protected route');

	const navigate = useNavigate();
	const onRedirectCallback = (appState) => {
		navigate(appState?.returnTo || window.location.pathname);
	};

	if (Cookies.get().id == 'undefined')
		Cookies.remove("id");

	if (Cookies.get().lastLogin && Cookies.get().id) {
		// console.log(new Date().getTime());
		// let hours = Math.abs(
		// 	Math.round(new Date().getTime() / 1000 - Cookies.get().lastLogin) / 36e5
		// );

		let hours =
			Math.floor((Date.now() - Cookies.get().lastLogin) / 1000) / 3600;
		// console.log(hours);
		if (hours > 1) {
			console.log("Hrs > 1");
			Cookies.set("lastLogin", Date.now(), { expires: 90 });
			async () => {
				const user_ref = await doc(db, "users", Cookies.get().id);
				await updateDoc(user_ref, {
					lastLogin: Date.now(),
				});
			};
		}
	} else if (Cookies.get().id && !Cookies.get().lastLogin) {
		Cookies.set("lastLogin", Date.now(), { expires: 90 });
		async () => {
			const user_ref = await doc(db, "users", Cookies.get().id);
			await updateDoc(user_ref, {
				lastLogin: Date.now(),
			});
		};
	}

	const { user, setUser } = useContext(UserContext);
	const Auth = Cookies.get().id ? true : false;

	// save project id in cookies
	/*
	let href = window.location.href;
	href = href.split('/');
	if (!Auth && href[4] == 'project' && href[5])	{
		let projectId = href[5];
		Cookies.set('projectId', projectId, { path: '/' });
	}
	*/

	const { code } = useParams();
	if (typeof(code) != 'undefined'){

		let temp = DATA;
		temp['projectId'] = code;

		setDATA(temp);
		//console.log('code = '+code);
	}

	const [searchParams] = useSearchParams();
	let unlock = searchParams.get("unlock");
	if (unlock){
		Cookies.set('unlock', unlock, { expires: 90, path: '/' });
	} else {
		if (code && code != Cookies.get().projectId)
			Cookies.remove('unlock');
	}

	if (code)
		Cookies.set('projectId', code, { expires: 90, path: '/'  });

	//console.log('all cookies');
    //console.log(Cookies.get());

	return Auth ? children : <Navigate to="/signup" />;
}
