import React, { useEffect, useState, useContext } from "react";
import { ReactComponent as LagoonLogo } from "../assets/logo.svg";
import { UserContext } from "./User";
import { ProgressCircleComponent, LevelProfileComponent } from '@insertcoinab/gwen-react-components';
import { DataContext } from "./DataContext";

export default function HeaderGreetings({ name, avatar, score }) {
	const [scroll, setScroll] = useState(false);
	const [time_of_day, setTimeOfDay] = useState("to see you");
	const [newAvatar, setNewAvatar] = useState("#");
	const { user } = useContext(UserContext);
	const [newScore, setNewScore] = useState(score);
    const {DATA, setData} = useContext(DataContext);
    const [Logo, setLogo] = useState("");
    
	useEffect(() => {
		window.addEventListener("scroll", () => {
			setScroll(window.scrollY > 50);
		});
		let today = new Date();
		let curHr = today.getHours();

		if (curHr < 12) {
			setTimeOfDay("morning");
		} else if (curHr < 18) {
			setTimeOfDay("afternoon");
		} else {
			setTimeOfDay("evening");
		}
	}, []);

	useEffect(() => {
		if (avatar) {
			setNewAvatar(avatar);
		}
	}, [avatar]);

	useEffect(() => {
		if (score) {
			setNewScore(score);
		}
	}, [score]);
	
    useEffect(() => {
        
		if (typeof(DATA) !== 'undefined' && typeof(DATA['logo']) !== 'undefined' && DATA['logo'] != '')
			setLogo(DATA['logo']);		
	}, [DATA, user.project_id]);
    

	// useEffect(() => {
	// 	if (user.progress) {
	// 		setAvatar(
	// 			user.progress.packet_1.activities.pdf.completed
	// 				? "/img/avatar-1.png"
	// 				: "/img/helmet-1.png"
	// 		);
	// 	}
	// }, [user]);

	return (
		<>
			<header className={`header-greetings ${scroll ? "bg-solid" : ""}`}>
				<div className="logo-wrap">
                    {Logo ? 
                        
                        <img src={Logo} />
                        :
                        
                        <LagoonLogo />
                    }
					
				</div>
				<div className="greetings">
					<div id="hi">
						<div id="greet">
							<div style={{ width: '80px', margin: '24px 14px 24px 24px' }}>
								<ProgressCircleComponent
									currentStep={newScore}
									step={newScore}
									shape="shield"
									progress={newScore / 80}
									avatar={newAvatar}
									stroke={{ primary: '#6946aa', background: '#a57af7' }}
									style={{ margin: '0 20px' }}
								/>
							</div>
							{/* <img className="avatar" src={newAvatar} alt="avatar" /> */}
							Good {time_of_day}
							<p className="name"> {name}</p>
						</div>
					</div>
				</div>
			</header>
		</>
	);
}
