import db from "../firebase";
import { addDoc, collection } from "@firebase/firestore";

export default function useAnalytics(action, id, optional) {

	const addAnalytics = async () => {
		const _ref_actions = await collection(db, "actions");
		const _optional = !optional ? {} : optional;
		addDoc(_ref_actions, {
			user_id: id,
			action: action,
			analytic_data: _optional,
		});
		// umami.trackEvent(action);
		// umami.trackEvent(action);
		// Check if Umami is loaded
		// debugger;
		if (window.umami) {
			if (optional && optional.fileName) {
				window.umami.track(action + ": " + optional.fileName);
			} else if (optional && optional.url) {
				window.umami.track(action + ": " + optional.url);
			} else {
				window.umami.track(action);
			}

		} else {
			console.error('Umami not loaded');
		}
	};
	return [addAnalytics];
}
