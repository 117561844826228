import Cookies from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router";
import useAnalytics from "../hooks/useAnalytics";
import useScore from "../hooks/useScore";
import useProgress from "../hooks/useProgress";
import Footer from "./Footer";
import ConfettiComp from "./ConfettiComp";
import TaskCard from "./TaskCard";
import { UserContext } from "./User";
import ArrowLeft from "./ArrowLeft";
import {
	deleteField,
	doc,
	getDoc,
	addDoc,
	collection,
	query,
	updateDoc,
	where,
} from "firebase/firestore";
import db from "../firebase";
import { DataContext } from "./DataContext";

const Downloader = () => {

	const { DATA, setData } = useContext(DataContext);

	const { user, setUser } = useContext(UserContext);

	const location = useLocation();
	const { packet_id } = location.state;
	const { activity_i } = useParams();
	const activity_index = activity_i - 1;
	const packet_index = parseInt(packet_id.replace(/packet_/, '') - 1);
	const { points } = location.state;

	const { data } = location.state;
	const navigate = useNavigate();
	const id = Cookies.get().id;
	const [updateScore] = useScore(points, id);
	const [addAnalytics] = useAnalytics("download pdf", id, data);
	const [fire, setFire] = useState(false);
	const [package_points, setPackage_points] = useState(0);
	const [updateProgress] = useProgress(user.progress, id);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		console.log(data)
		if (user.progress && user.project_id && user.progress[user.project_id]) {
			setPackage_points(user.progress[user.project_id]['packets'][packet_index].package_points);
			console.log('points set! ', package_points)
		}
	}, [user, user.progress, user.project_id]);

	const update = async () => {
		const user_ref = await doc(db, "users", Cookies.get().id);

		if (DATA['PACKETS'][packet_index].activities[activity_index]['reward']) {

			let reward = DATA['PACKETS'][packet_index].activities[activity_index]['reward'];

			if (reward.name != '') {
				user.rewards.push({
					name: reward.name,
					price: reward.price,
					image: reward.image,
					redeemed: false,
					instruction: reward?.instruction,
				});
			}
		}

		user.setprogress = false;
		setUser(Object.assign({}, user));

		// await updateScore();

		// debugger;

		console.log(user.progress[user.project_id]['packets'][packet_index].activities[activity_index].completed);

		user.progress[user.project_id]['packets'][packet_index].activities[activity_index].completed = true;
		user.progress[user.project_id]['packets'][packet_index].package_points = parseInt(user.progress[user.project_id]['packets'][packet_index].package_points) + parseInt(points);
		console.log('points set! ', user.progress[user.project_id]['packets'][packet_index].package_points)

		console.log(user.progress[user.project_id]['packets'][packet_index].activities[activity_index].completed);

		await updateDoc(user_ref, {
			// progress: tempProgress,
			rewards: user.rewards,
		});
	};

	const handleClick = async () => {
		await updateScore();
		await addAnalytics();
		await update();
		await updateProgress();
		// window.open(
		// 	//"https://firebasestorage.googleapis.com/v0/b/lagoon-118b4.appspot.com/o/lagoon.pdf?alt=media&token=e6fa23be-c233-4228-bbea-aa9b3afb5ea0",
		// 	data.assetUrl,
		// 	"_blank",
		// 	"fullscreen=yes"
		// );
		setFire(true);
	};

	useEffect(() => {
		if (fire) {
			setTimeout(() => {
				navigate("/");
			}, 1000);
		}
	}, [fire]);



	return (
		<div className="downloader">
			<header>
				<div className="top">
					<ArrowLeft />
					Download PDF
				</div>
			</header>
			<div id="top-downloader">
				{/* <ArrowLeft /> */}

				<div className="flex">
					{/* <p className="mid-featured"> Welcome to Download page!</p> */}
					<ConfettiComp fire={fire} />
					{/* <img src={data?.imgPreview}></img> */}
					<TaskCard
						taskDescription={data.taskDescription}
						buttonText={data.buttonText}
						handleClick={handleClick}
						imagePreview={data?.imgPreview}
					/>
					<div className="flex flex-col"></div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default Downloader;
